import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Button, Grid, Input, Snackbar, Stack, Table, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/Time'
import { DataGrid } from '@mui/x-data-grid'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'
import { Edit, EditOffOutlined } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const JoinedUsers = () => {
    const [apiCalled, setApiCalled] = React.useState(false)
    const location = useLocation()
    const [inputUid, setInputUid] = React.useState('')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [usersList, setUsersList] = React.useState([])
    const [awarded, setAwarded] = React.useState(true)

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
        setInputUid('')
    }

    const getLeaderboardOfContest = (contestId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/leaderboard`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    setUsersList(data.data.list)
                    setAwarded(data.data.awarded)
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        const path = location.pathname
        const contestId = path.substring(22)
        console.log('Contest Id : ', contestId)
        getLeaderboardOfContest(contestId)
        window.scrollTo(0, 0)
    }, [])

    const handleEditQuestion = (id) => {
        navigate('/edit-user?id=' + id)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className=''>
                            <h1 className='text-2xl font-bold my-5 text-gray-700'> Users List</h1>
                        </Grid>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                <>
                                    <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align='left'>S. No</StyledTableCell>
                                                <StyledTableCell align='left'>Username</StyledTableCell>
                                                <StyledTableCell align='left'>Points</StyledTableCell>
                                                <StyledTableCell align='left'>Edit</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usersList.length > 0 &&
                                                usersList.map((data, index) => {
                                                    return (
                                                        <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.username}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.coins}</StyledTableCell>
                                                            <StyledTableCell align='left' onClick={() => handleEditQuestion(data.uid)}>
                                                                <Edit sx={{ cursor: 'pointer'}} />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </>
                            </Grid>
                        </Box>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default JoinedUsers
