import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Button, Card, CardContent, Paper, Snackbar, Stack, Typography, useMediaQuery } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { AddAPhoto, BrowserUpdatedSharp, CardGiftcard, DashboardCustomizeSharp, DashboardRounded, DataArray, DeleteForever, Home, LocalActivityOutlined, Logout, MilitaryTech, OpenInBrowserSharp, PagesOutlined, Pageview, PausePresentation, Person, PropaneOutlined, PropaneSharp, SecurityUpdateSharp } from '@mui/icons-material'
function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Add Topic',
            route: 'add-topic',
            icon: <Person />
        },
        {
            value: 'Add Question',
            route: 'add-question',
            icon: <MilitaryTech />
        },
        {
            value: 'Recent Activity',
            route: 'recent-activity',
            icon: <LocalActivityOutlined />
        }
    ]

    const mainListItems = [
        {
            value: 'Joined Users',
            route: 'joined-users',
            icon: <Person />
        },
        {
            value: 'Reported Questions',
            route: 'reported-questions',
            icon: <SecurityUpdateSharp />
        },
        {
            value: 'Deleted Topics',
            route: 'deleted-topics',
            icon: <DeleteForever />
        }
    ]

    const featuresItems = [
        {
            value: 'Add Onboarding',
            route: 'add-onboarding',
            icon: <OpenInBrowserSharp />
        },
        {
            value: 'View Onboarding',
            route: 'view-onboarding',
            icon: <BrowserUpdatedSharp />
        }
    ]

    const examItems = [
        {
            value: 'Add Exam',
            route: 'add-exam',
            icon: <PropaneSharp />
        },
        {
            value: 'View Exams',
            route: 'view-exams',
            icon: <PropaneOutlined />
        }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {featuresItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {examItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem key={12} disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
