/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import checkIcon from '../../Assets/checked.png'
import './Home.css'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function Home() {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }

    const [contests, setContests] = useState([])
    const [topicsList, setTopicsList] = useState([])
    const [upcomingContests, setUpcomingContests] = useState([])
    const [liveContests, setLiveContests] = useState([])
    const [completedContests, setCompletedContests] = useState([])
    const [hiddenContests, setHiddenContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const handleEditClick = (id) => {
        navigate('/edit-contest/' + id)
    }

    const getTopicsList = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/quiz/topic`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setTopicsList(data.data.topics)
                    setApiCalled(true)
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const navigateToTopicQuestionsPage = (id) => {
        navigate('/topic-questions/' + id)
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='mt-4'>
                        <div className='text-3xl font-bold text-gray-700 my-8 text-center'>RijSlim Admin Dashboard</div>
                        <div className='all__tickets__container'>
                            {topicsList.length > 0 &&
                                topicsList.map((topic) => {
                                    return (
                                        <div class='tickets__card__container cursor-pointer' onClick={() => navigateToTopicQuestionsPage(topic._id)}>
                                            <article class='each__ticket__container'>
                                                <div>
                                                    <h2 className='font-bold'> {topic.name}</h2>
                                                    <h2 className='mb-2'> {topic.description}</h2>
                                                    <div class='text-xs text-gray-500'>
                                                        Last Updated {showTimeInAgoFormat(topic.updatedAt)} • Questions : {topic.questions ? topic.questions : 0}
                                                    </div>
                                                    <div class='card-meta-snippets'></div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}

                            {topicsList.length === 0 && (
                                <>
                                    <img className='h-20' src={checkIcon} alt='' />
                                    <p className='text-2xl text-gray-500 mt-2'>No Topics found</p>
                                </>
                            )}
                        </div>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                    Success!!!
                                </Alert>
                            </Snackbar>
                        </Stack>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                    Error!!!
                                </Alert>
                            </Snackbar>
                        </Stack>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default Home
