import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Autocomplete, Button, Checkbox, FormControlLabel, Grid, Snackbar, Stack, TextField, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { BASE_URL } from '../../Api/Constants'
import { categoryList, filterList, yesNoList } from '../../Utils/AutoComplete'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddQuestion = () => {
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [startTime, setStartTime] = useState('09:00')
    const [endDate, setEndDate] = useState(new Date().getTime() + 86400000 * 3)
    const [endDateInputValue, setEndDateInputValue] = useState(formatDateToYYYYMMDD(endDate))
    const [quizName, setQuizName] = useState('')
    const [quizDescription, setQuizDescription] = useState('')

    const isMobile = useMediaQuery('(max-width:600px)')

    const [topicName, setTopicName] = useState('')
    const [questionValue, setQuestionValue] = useState('')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [optionD, setOptionD] = useState('')
    const [correctOption, setCorrectOption] = useState('')
    const [explanation, setExplanation] = useState('')
    const [questionType, setQuestionType] = useState('Text')
    const [questionCategory, setQuestionCategory] = useState('None')
    const [examPool, setExamPool] = useState(false)
    const [imageURL, setImageURL] = useState('')
    const [topicsList, setTopicsList] = useState([{ label: 'Road Safety' }])
    const [apiCalled, setApiCalled] = useState(false)

    const handleCheckboxChange = (event) => {
        setExamPool(event.target.checked)
    }

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setTopicName('')
        setQuestionValue('')
        setOptionA('')
        setOptionB('')
        setOptionC('')
        setOptionD('')
        setCorrectOption('')
        setExplanation('')
    }

    const handleTopicsList = (topics) => {
        const newTopics = []
        topics.forEach((topic) => {
            newTopics.push({ label: topic.name })
        })
        setTopicsList(newTopics)
    }

    const addNewContest = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: questionType, topic: topicName, category: questionCategory, examPool, question: questionValue, options: [optionA.trim(), optionB.trim(), optionC.trim(), optionD.trim()], imageURL, correctOption: correctOption, explanation })
        }

        fetch(`${BASE_URL}/api/admin/quiz/question`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const getTopicsList = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/quiz/topic`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    handleTopicsList(data.data.topics)
                    setApiCalled(true)
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500 my-5'> Add New Question</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                value={questionType}
                                options={filterList}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setQuestionType(item.label)
                                }}
                                renderInput={(params) => <TextField {...params} label='Question Type' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <FormControlLabel sx={{ width: isMobile ? '90%' : '50%' }} control={<Checkbox checked={examPool} onChange={handleCheckboxChange} color='primary' />} label='Include in Exam Pool' />
                        </Grid>

                        {examPool && (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Autocomplete
                                    disablePortal
                                    id='combo-box-demo'
                                    value={questionCategory}
                                    options={categoryList}
                                    sx={{ width: isMobile ? '90%' : '50%' }}
                                    onChange={(event, item) => {
                                        setQuestionCategory(item.label)
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Question Category' />}
                                />
                            </Grid>
                        )}

                        {questionType === 'Image' && (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Image URL'
                                    id='combo-box-demo'
                                    value={imageURL}
                                    sx={{ width: isMobile ? '90%' : '50%' }}
                                    onChange={(event, item) => {
                                        setImageURL(event.target.value)
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                value={topicName}
                                options={topicsList}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setTopicName(item.label)
                                }}
                                renderInput={(params) => <TextField {...params} label='Choose Topic' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Question'
                                id='combo-box-demo'
                                value={questionValue}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setQuestionValue(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Option A'
                                id='combo-box-demo'
                                value={optionA}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setOptionA(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Option B'
                                id='combo-box-demo'
                                value={optionB}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setOptionB(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Option C'
                                id='combo-box-demo'
                                value={optionC}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setOptionC(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Option D'
                                id='combo-box-demo'
                                value={optionD}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setOptionD(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                value={correctOption}
                                options={[optionA, optionB, optionC, optionD].filter((option) => option !== '')}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setCorrectOption(item)
                                }}
                                renderInput={(params) => <TextField {...params} label='Correct Option' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Explanation'
                                id='combo-box-demo'
                                value={explanation}
                                sx={{ width: isMobile ? '90%' : '50%' }}
                                onChange={(event, item) => {
                                    setExplanation(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: isMobile ? '90%' : '50%' }} color='info' onClick={addNewContest} disabled={!questionValue || !topicName || !correctOption}>
                                Add Question
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddQuestion
