import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function EditTopic() {
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const oldTitleName = searchParams.get('topic')
    const [newName, setNewName] = useState('')

    useEffect(() => {
        // Fetch data or set initial values if needed
    }, []) // Add dependencies if needed

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/topic`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    oldName: oldTitleName,
                    newName: newName.trim()
                })
            })
            if (response.ok) {
                console.log('Subject modified successfully')
                setNewName('')
                navigate('/')
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4'>Modify Topic</Typography>
                    <TextField label='Old Topic' value={oldTitleName} fullWidth variant='outlined' margin='normal' disabled />
                    <TextField label='New Topic' value={newName} onChange={(event) => setNewName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
