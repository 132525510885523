import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Autocomplete, Button, Grid, Input, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddTopic = () => {
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [startTime, setStartTime] = useState('09:00')
    const [endDate, setEndDate] = useState(new Date().getTime() + 86400000 * 3)
    const [endDateInputValue, setEndDateInputValue] = useState(formatDateToYYYYMMDD(endDate))
    const [quizName, setQuizName] = useState('')
    const [quizDescription, setQuizDescription] = useState('')
    const [topicImage, setTopicImage] = useState('')
    const [quizTime, setQuizTime] = useState(30)

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setQuizName('')
        setQuizDescription('')
    }

    const addNewContest = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: quizName, description: quizDescription, image: topicImage })
        }

        console.log('Request Options : ', requestOptions)

        fetch(`${BASE_URL}/api/quiz/topic`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500 my-5'> Add New Topic</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Name'
                                id='combo-box-demo'
                                value={quizName}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setQuizName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                label='Description'
                                id='combo-box-demo'
                                value={quizDescription}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setQuizDescription(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                id='combo-box-demo'
                                value={topicImage}
                                options={['road_safety_image', 'car_image']}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setTopicImage(item)
                                }}
                                renderInput={(params) => <TextField {...params} label='Topic Image' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='info' onClick={addNewContest}>
                                Add Topic
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddTopic
